import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FiExternalLink } from "react-icons/fi";

function ProjectCards({ imgPath, title, description, link, techIcons }) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={imgPath} alt="card-img" className="card-img-top rounded-img" />
      <Card.Body style={{ position: "relative", paddingBottom: "50px" }}>
        <Card.Title>{title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {description}
        </Card.Text>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          {techIcons.map((Icon, index) => (
            <Icon key={index} size={30} style={{ marginRight: "10px" }} />
          ))}
        </div>
        <Button
          variant="primary"
          href={link}
          target="_blank"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
        >
          <FiExternalLink />
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
