import React, { useRef, useState } from "react";
import { FaFigma, FaHtml5, FaCss3Alt, FaPhp } from "react-icons/fa";
import { SiPowerbi } from "react-icons/si";
import { DiMsqlServer, DiPython } from "react-icons/di";

function Techstack() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className="techstack-container"
      ref={containerRef}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div className="techstack-slider">
        <div className="tech-item">
          <DiPython />
          <span>Python</span>
        </div>
        <div className="tech-item">
          <DiMsqlServer />
          <span>SQL Server</span>
        </div>
        <div className="tech-item">
          <FaPhp />
          <span>PHP</span>
        </div>
        <div className="tech-item">
          <FaHtml5 />
          <span>HTML5</span>
        </div>
        <div className="tech-item">
          <FaCss3Alt />
          <span>CSS3</span>
        </div>
        <div className="tech-item">
          <SiPowerbi />
          <span>Power BI</span>
        </div>
        <div className="tech-item">
          <FaFigma />
          <span>Figma</span>
        </div>
      </div>
    </div>
  );
}

export default Techstack;
