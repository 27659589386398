import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      options={{
        autoPlay: true,
        clear: true,
        fullScreen: {
          enable: true,
          zIndex: 0,
        },
        detectRetina: true,
        fpsLimit: 120,
        interactivity: {
          detectsOn: "window",
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "bubble",
            },
            resize: {
              delay: 0.5,
              enable: true,
            },
          },
          modes: {
            bubble: {
              distance: 200,
              duration: 2,
              opacity: 1,
              size: 30,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
              factor: 100,
              speed: 1,
              maxSpeed: 40,
              easing: "ease-out-quad",
            },
          },
        },
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              width: 1920,
              height: 1080,
            },
          },
          color: {
            value: ["#1E90FF", "#5CACEE", "#87CEEB", "#B0E0E6", "#E0FFFF"],
          },
          opacity: {
            value: 0.5,
          },
          size: {
            value: { min: 1, max: 20 },
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            outModes: {
              default: "out",
            },
          },
          links: {
            enable: true,
            distance: 150,
            value: ["#1E90FF", "#5CACEE", "#87CEEB", "#B0E0E6", "#E0FFFF"],
            opacity: 1,
            width: 1,
          },
        },
        pauseOnBlur: true,
        pauseOnOutsideViewport: true,
        zLayers: 100,
        name: "Background Mask",
        motion: {
          disable: false,
          reduce: {
            factor: 4,
            value: true,
          },
        },
      }}
    />
  );
}

export default Particle;
