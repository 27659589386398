import React from "react";
import { Button } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";

function ActionButtons() {
  return (
    <div className="home-buttons">
      <Button
        variant="primary"
        href="/RodrigoLarroca_CV.pdf"
        target="_blank"
        className="custom-button"
      >
        <BsDownload /> &nbsp; Curriculum
      </Button>
      <Button
        variant="secondary"
        className="custom-button"
        onClick={() => document.getElementById('contact-section').scrollIntoView()}
      >
        <FaPhoneAlt /> &nbsp; Contacto
      </Button>
    </div>
  );
}

export default ActionButtons;
