import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/yo.svg";
import Particle from "../Particle";
import Type from "./Type";
import Buts from "./Buts";
import About from "../About/About";
import Experiencia from "./Experiencia";
import Projects from "../Projects/Projects";
import {
  AiFillGithub,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name"> Rodrigo Larroca</strong>
              </h1>
              <div className="home-header-content">
                <Type />
              </div>
              <div className="home-buttons">
                <Buts />
              </div>
              <div className="social-gral">
                <ul className="home-about-social-links">
                  <li className="social-icons">
                    <a
                      href="https://github.com/rodrigolarroca"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                    >
                      <AiFillGithub />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.linkedin.com/in/rodrigolarroca/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={5} style={{ paddingBottom: 75 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid home-logo"
                style={{ maxHeight: "400px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <About />
      <Experiencia />
      <Projects />
    </section>
  );
}

export default Home;
