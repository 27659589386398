import React, { useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography, Paper, Button } from '@mui/material';
import { Container } from 'react-bootstrap';

const experiences = [
  {
    role: "Analista de procesos administrativos",
    company: "Versatil SRL",
    description: "Arquitectura e interfaces de carga, sistemas de control de obras & dashboards del area. Desarrollo de aplicaciones moviles (CRM) y modulares.",
    date: "Abril 2024 - Presente",
    //link: ""
  },
  {
    role: "Administrador de tienda online",
    company: "Onirico",
    description: "Dueño de tienda online de productos ondemand.",
    date: "2024 - Presente",
    link: "https://oniricostyle.store/"
  },
  {
    role: "Ssr. Analista de datos",
    company: "Nea Servicios Integrales SRL",
    description: "Arquitectura e interfaces de carga, sistemas de gestion de calidad & dashboards en areas de RRHH & Operaciones: ausentismos, reclamos, satisfaccion, demanda y envio de insumos, nominas del personal, novedades de supervision. Modelos de prediccion en demanda de insumos.",
    date: "Marzo 2023 - Diciembre 2023 · 10 meses",
    //link: ""
  },
  {
    role: "Jr. Analista de datos",
    company: "Centro de atencion primaria de salud (CAPS-3)",
    description: "Arquitectura e interfaces de carga, dashboards en salud & contabilidad. Sistemas en inventarios de farmacia, libros/impuestos&sellos, analisis de pacientes (datos antropométricos, enfermedades, evolucion de pacientes).",
    date: "Junio 2021 - Marzo 2023 · 2 años 9 meses",
    //link: ""
  }
];

export default function ExperienceTimeline() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Container fluid className='project-section' id="experiencia">
      <h1 className="project-heading">
        Mis <strong className="purple">Experiencias</strong>
      </h1>
      <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
        {experiences.map((exp, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: hoveredIndex === index ? '#004d99' : '#70bdf0',
                  width: hoveredIndex === index ? 24 : 16,
                  height: hoveredIndex === index ? 24 : 16,
                  transition: 'transform 0.3s, background-color 0.3s, width 0.3s, height 0.3s',
                  transform: hoveredIndex === index ? 'scale(1.2)' : 'none',
                }}
              />
              {index < experiences.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper
                elevation={3}
                sx={{
                  padding: '16px',
                  backgroundColor: 'rgba(0, 0, 32, 0.3)',
                  color: 'white',
                  marginLeft: '20px',
                  backdropFilter: 'blur(5px)',
                  WebkitBackdropFilter: 'blur(5px)', 
                  transition: 'background-color 0.3s, box-shadow 0.3s, transform 0.3s',
                  transform: hoveredIndex === index ? 'scale(1.05)' : 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 50, 0.7)',
                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)', 
                  }
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Typography variant="h6" component="span">
                  {exp.role}
                </Typography>
                <Typography variant="body1">
                  {exp.company}
                </Typography>
                <Typography variant="body2">
                  {exp.date}
                </Typography>
                <Typography variant="body2" paragraph>
                  {exp.description}
                </Typography>
                {exp.link && (
                  <Button
                    variant="contained"
                    href={exp.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      mt: 2,
                      backgroundColor: '#004d99',
                      color: '#fff',
                      '&:hover': {
                        color: '#fff',
                        backgroundColor: '#003366',
                        transform: 'scale(1.05)',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                      }
                    }}
                  >
                    Ver el negocio...
                  </Button>
                )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Container>
  );
}