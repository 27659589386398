import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import { FaPython, FaPhp, FaWordpress, FaFigma, FaGoogleDrive } from "react-icons/fa";
import { SiAdobephotoshop, SiWoo, SiPowerbi, SiTensorflow, SiKeras, SiAstro, SiTailwindcss, SiMicrosoftsqlserver, SiMysql } from "react-icons/si";
import Particle from "../Particle";
import rave from "../../Assets/Projects/Rave1.webp";
import prediccion from "../../Assets/Projects/fraud.webp";
import calidad from "../../Assets/Projects/starwars.webp";
import webs from "../../Assets/Projects/Navy1.webp";
import crud from "../../Assets/Projects/crudphp.webp";
import tienda from "../../Assets/Projects/Onirico1.webp";

function Projects() {
  return (
    <Container fluid className="project-section" id="projects">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mis <strong className="purple">Proyectos </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tienda}
              isBlog={false}
              title="Onirico Store"
              description="Tienda de ropa de productos ONDEMAND. Envios internacionales y pagos automatizados. Diseño de indumentaria"
              link="https://oniricostyle.store/"
              techIcons={[FaWordpress, SiWoo, FaFigma, SiAdobephotoshop]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={calidad}
              isBlog={false}
              title="Gestion automatizado de calidad ISO"
              description="Tableros de control e interfaces de carga para un completo control ante auditorias de calidad, cumpliendo los estandares de las ISO-9001"
              link="https://app.powerbi.com/view?r=eyJrIjoiNGQ0MDMzNDctZTE3Yy00YTE0LWJhMTAtNWY1NzFmMTdhYjNhIiwidCI6ImU2ZjUyMTk5LTY5NzMtNDEyNC04Zjc1LWRhMGUwMWRhNDgyYyJ9"
              techIcons={[FaPython, SiPowerbi, FaGoogleDrive]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={prediccion}
              isBlog={false}
              title="Detecciones y predicciones"
              description="Modelos de aprendizaje aautomatizado para predicciones y detecciones (predicciones en creditos, detecciones de fraude, etc)"
              link="https://github.com/RodrigoLarroca/Nostalgia_Jupyter_Inicios/blob/main/Deteccion_fraude_deeplerningtensorflow.ipynb"
              techIcons={[FaPython, SiTensorflow, SiKeras, SiPowerbi]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rave}
              isBlog={false}
              title="Copia de Rave"
              description="Copia de la web de Rave, usando Astro y Tailwind"
              link="https://github.com/RodrigoLarroca/ravecopy"
              techIcons={[SiAstro, SiTailwindcss, FaFigma]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webs}
              isBlog={false}
              title="Web de servicios en data"
              description="Pagina web para ofrecer servicios en datos"
              link="https://datanavy.net/"
              techIcons={[FaWordpress, SiWoo, FaFigma, SiAdobephotoshop]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crud}
              isBlog={false}
              title="Cruds & Crms"
              description="Distintos cruds en php o python, asi también CRM con funciones de agendas, historial y gráficos."
              link="https://github.com/RodrigoLarroca/CRUD_PHP-SQLServer"
              techIcons={[FaPhp, FaPython, SiMicrosoftsqlserver, SiMysql]}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
